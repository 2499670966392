import EditNoteIcon from '@mui/icons-material/EditNote';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import * as React from 'react';
import { useCallback, useContext } from 'react';
import { userApi } from '../../api/user/user';
import { UserContext } from '../../auth/context/user';
import useObject from '../../hooks/use-object';
import useString from '../../hooks/use-string';
import PaperBox from '../../standard/complementary/paper-box';
import useInit from '../../standard/hooks/useInit';
import useRequest from '../../standard/hooks/useRequest';
import PrimaryButton from '../../standard/primary/primary-button';
import PrimaryTextarea from '../../standard/primary/primary-textarea';

const HeaderQuickNote = () => {
  const { userId } = useContext(UserContext);
  const { onRequest, isLoading } = useRequest();
  const noteKv = useObject(null);
  const noteText = useString('');

  const onGetQuickNote = useCallback(async () => {
    const response = await onRequest(
      userApi.getUserKeyValue,
      [userId, 'quick-note', 'note'],
      null,
      false
    );

    noteKv.onChangeValue(response?.result);
    noteText.onChangeValue(response?.result?.value);
  }, [noteKv, noteText, onRequest, userId]);

  const onUpdateNote = useCallback(async () => {
    const newNoteKv = { ...noteKv.value, value: noteText.value };
    await onRequest(userApi.updateUserKeyValue, [userId], newNoteKv, true);
  }, [noteKv, noteText.value, onRequest, userId]);

  useInit(onGetQuickNote);
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { size: 'sm', variant: 'plain', color: 'neutral' } }}
      >
        <EditNoteIcon />
      </MenuButton>
      <Menu sx={{ width: '33%' }}>
        <PaperBox>
          <PrimaryTextarea
            label="Quick Note"
            value={noteText.value}
            onChange={noteText.onChangeValue}
            minRows={4}
          />
          <PrimaryButton disabled={isLoading} onClick={onUpdateNote}>
            Submit
          </PrimaryButton>
        </PaperBox>
      </Menu>
    </Dropdown>
  );
};

export default HeaderQuickNote;
