import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard';
import ProductLayout from '../../layouts/product';
import SubscriptionPage from '../../pages/dashboard/account/subscription';

const Live2dPage = lazy(() => import('../../pages/dashboard/account/live2d'));
const TermsOfService = lazy(() => import('../../pages/others/terms-of-service'));
const BookPage = lazy(() => import('src/pages/dashboard/analytics/reads/book'));
const OverviewReadsPage = lazy(() => import('src/pages/dashboard/analytics/reads'));
const TextToVideoPage = lazy(() => import('src/pages/dashboard/analytics/text-to-video'));
const OverviewWorksPage = lazy(() => import('src/pages/dashboard/analytics/works'));
const PostsPage = lazy(() => import('src/pages/dashboard/logistics/post/list'));
const PostPage = lazy(() => import('src/pages/dashboard/logistics/post/details'));
const KpicPage = lazy(() => import('src/pages/dashboard/kpic'));
const GalleryPage = lazy(() => import('src/pages/dashboard/logistics/gallery'));
const CalendarPage = lazy(() => import('src/pages/dashboard/logistics/calendar'));
const InfoPage = lazy(() => import('src/pages/dashboard/account/info'));
const FinancePage = lazy(() => import('../../pages/dashboard/kpic/finance'));

const CapacityPage = lazy(() => import('../../pages/dashboard/kpic/capacity'));
const DashboardPage = lazy(() => import('../../pages/dashboard/dashboard'));
const Page404 = lazy(() => import('../../pages/others/page404'));
const ProductPage = lazy(() => import('../../pages/product'));

export const dashboardRoutes = [
  {
    path: 'product',
    element: (
      <ProductLayout>
        <ProductPage />
      </ProductLayout>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      { element: <DashboardPage />, index: true },
      {
        path: 'analytics',
        children: [
          { element: <OverviewReadsPage />, index: true },
          { path: 'book', element: <BookPage /> },
          { path: 'tts', element: <OverviewReadsPage /> },
          { path: 'ttv', element: <TextToVideoPage /> },
          { path: 'works', element: <OverviewWorksPage /> },
        ],
      },
      {
        path: 'logistics',
        children: [
          { element: <CalendarPage />, index: true },
          { path: 'calendar', element: <CalendarPage /> },
          {
            path: 'gallery',
            children: [{ element: <GalleryPage />, index: true }],
          },
          {
            path: 'kpic',
            children: [
              { element: <KpicPage />, index: true },
              { path: 'finance', element: <FinancePage /> },
              { path: 'capacity', element: <CapacityPage /> },
            ],
          },
          {
            path: 'post',
            children: [
              { element: <PostsPage />, index: true },
              { path: ':id', element: <PostPage /> },
            ],
          },
        ],
      },
      {
        path: 'account',
        children: [
          { path: 'info', element: <InfoPage /> },
          {
            path: 'subscription',
            element: <SubscriptionPage />,
            children: [{ path: ':sessionId', element: <SubscriptionPage /> }],
          },
          { path: 'live2d', element: <Live2dPage /> },
        ],
      },
    ],
  },
  {
    path: 'terms-of-service',
    element: <TermsOfService />,
  },
  { path: '404', element: <Page404 /> },
];
