import { useCallback } from 'react';
import { voiceApi } from '../../api/speech/voice';
import useArraySelection from './useArraySelection';
import useInit from './useInit';
import useRequest from './useRequest';

const useVoiceList = () => {
  const { onRequest } = useRequest();
  const voiceList = useArraySelection([]);

  const onUpdate = useCallback(async () => {
    const response = await onRequest(voiceApi.getTTSVoices, [], null, false);
    const selection = response?.result?.map((voiceName, i) => ({
      id: i,
      name: voiceName,
    }));
    voiceList.onModifyArray(selection ?? []);
  }, [onRequest, voiceList]);

  useInit(onUpdate);
  return voiceList;
};

export default useVoiceList;
