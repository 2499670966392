import CloseIcon from '@mui/icons-material/Close';
import LanguageIcon from '@mui/icons-material/Language';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import React, { useState } from 'react';
import { useBoolean } from '../../../hooks/use-boolean';
import useString from '../../../hooks/use-string';
import ColumnStack from '../../../standard/primary/column-stack';
import PrimaryBox from '../../../standard/primary/primary-box';
import Word from '../../../standard/primary/word';
import live2dModels from '../../../values/live2d/config';
import texts from '../../../values/product/introduction';
import Live2dCanvas from '../../dashboard/account/live2d/components/live2d-canvas';
import useLive2dAudio from '../../dashboard/account/live2d/hooks/use-live2d-audio';

const WelcomeHeader = () => {
  const [currentModel, setCurrentModel] = useState(null);
  const { audioRef, isPlaying, playAudio } = useLive2dAudio(currentModel);

  const languages = ['中文', 'English'];
  const currentLanguage = useString(languages[1]);
  const isVisible = useBoolean(false);

  const isZh = currentLanguage.value === languages[0];
  const introText = isZh ? texts.introZh : texts.introEng;
  const introAudioPath = isZh ? '/product/intro/zh.wav' : '/product/intro/en.wav';
  return (
    <Box sx={{ marginBottom: 4 }}>
      <Live2dCanvas source={live2dModels.ryousan} setCurrentModel={setCurrentModel} />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} src="" onEnded={isPlaying.onFalse} />
      <div>
        <IconButton onClick={isVisible.onToggle}>
          <QuestionMarkIcon />
        </IconButton>

        {isVisible.value && (
          <PrimaryBox
            sx={{
              position: 'absolute',
              top: '90px',
              left: '0px',
              color: 'white',
            }}
          >
            <IconButton onClick={() => currentLanguage.onChangeValue(languages[isZh ? 1 : 0])}>
              <LanguageIcon />
              {currentLanguage.value}
            </IconButton>
            <IconButton onClick={() => playAudio(introAudioPath)}>
              <VolumeUpIcon />
            </IconButton>
            <IconButton onClick={isVisible.onFalse}>
              <CloseIcon />
            </IconButton>
            <PrimaryBox
              sx={{
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: 'rgba(0,0,0,0.6)',
                width: '500px',
                height: '550px',
                overflowY: 'auto',
              }}
            >
              <ColumnStack>
                {introText.split('\n').map((line, i) => (
                  <Word sx={{ color: 'white' }} key={i}>
                    {line}
                  </Word>
                ))}
              </ColumnStack>
            </PrimaryBox>
          </PrimaryBox>
        )}
      </div>
    </Box>
  );
};

export default WelcomeHeader;
