import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import HomeIcon from '@mui/icons-material/Home';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Drawer from '@mui/joy/Drawer';
import IconButton from '@mui/joy/IconButton';
import ModalClose from '@mui/joy/ModalClose';
import Stack from '@mui/joy/Stack';
import { useColorScheme } from '@mui/joy/styles';
import Tooltip from '@mui/joy/Tooltip';
import * as React from 'react';
import { useEffect } from 'react';
import { paths } from 'src/routes/paths';
import { useRouter } from '../../routes/hook';
import HeaderDropdown from './header-dropdown';
import HeaderQuickNote from './header-quick-note';
import NavigationJoyUi from './naviagation-joyui';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Tooltip title="Change theme" variant="outlined">
      <IconButton
        id="toggle-mode"
        size="sm"
        variant="plain"
        color="neutral"
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
        }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

export default function HeaderJoyUI() {
  const router = useRouter();

  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <IconButton
          size="md"
          variant="outlined"
          color="neutral"
          sx={{
            display: { xs: 'none', sm: 'inline-flex' },
            borderRadius: '50%',
          }}
          onClick={() => router.push(paths.product.root)}
        >
          <HomeIcon />
        </IconButton>
        <Button
          variant="plain"
          color="neutral"
          component="a"
          href={paths.dashboard.root}
          size="sm"
          sx={{ alignSelf: 'center' }}
        >
          Dashboard
        </Button>
      </Stack>
      <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
        <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <IconButton variant="plain" color="neutral" onClick={() => router.push(paths.product.root)}>
          <HomeIcon />
        </IconButton>

        <Button
          variant="plain"
          color="neutral"
          component="a"
          href={paths.dashboard.root}
          size="sm"
          sx={{ alignSelf: 'center' }}
        >
          Dashboard
        </Button>
        <Drawer sx={{ display: { xs: 'inline-flex' } }} open={open} onClose={() => setOpen(false)}>
          <ModalClose />
          <DialogTitle>
            <NavigationJoyUi onClose={() => setOpen(false)} />
          </DialogTitle>
          <Box sx={{ px: 1 }} />
        </Drawer>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          sx={{ display: { xs: 'inline-flex', sm: 'none' }, alignSelf: 'center' }}
        >
          <SearchRoundedIcon />
        </IconButton>

        <HeaderQuickNote />
        <ColorSchemeToggle />
        <HeaderDropdown />
      </Box>
    </Box>
  );
}
