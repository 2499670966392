import requestMethods from '../connection/requestMethods';

export const voiceApi = {
  updateAudioCaption: {
    method: requestMethods.put,
    path: () => `/voice/caption/audio`,
    okMessage: 'Caption is updated',
  },
  getAudioCaption: {
    method: requestMethods.get,
    path: (userId, id) => `/voice/caption/audio?userId=${userId}&id=${id}`,
    okMessage: 'Captioning job is started',
  },
  getSloganToSpeechWav: {
    method: requestMethods.get,
    path: (userId, i, voiceName) =>
      `/voice/tts/wav/slogan?userId=${userId}&i=${i}&voiceName=${voiceName}`,
    okMessage: 'Text-to-speech is completed',
  },
  getTextToSpeechMp3: {
    method: requestMethods.post,
    path: () => `/voice/tts/mp3`,
    okMessage: 'Text-to-speech is completed',
  },
  getTextToSpeechWav: {
    method: requestMethods.post,
    path: () => `/voice/tts/wav`,
    okMessage: 'Text-to-speech is completed',
  },
  createTextCollection: {
    method: requestMethods.post,
    path: (userId) => `/voice/text/upload?userId=${userId}`,
    okMessage: 'Resource is sent',
  },
  getTextCollection: {
    method: requestMethods.get,
    path: (userId) => `/voice/text/collection?userId=${userId}`,
    okMessage: 'Text collections are fetched',
  },
  getTextSection: {
    method: requestMethods.get,
    path: (userId, collectionId) =>
      `/voice/text/section?userId=${userId}&collectionId=${collectionId}`,
    okMessage: 'Text sections are fetched',
  },
  getTextSectionOne: {
    method: requestMethods.get,
    path: (userId, id) => `/voice/text/section/one?userId=${userId}&id=${id}`,
    okMessage: 'Text sections are fetched',
  },
  createTextSection: {
    method: requestMethods.post,
    path: (userId) => `/voice/text/section?userId=${userId}`,
    okMessage: 'Text section is created',
  },
  removeTextSection: {
    method: requestMethods.delete,
    path: (userId, id) => `/voice/text/section?userId=${userId}&id=${id}`,
    okMessage: 'Text section is removed',
  },
  updateTextSection: {
    method: requestMethods.post,
    path: (userId) => `/voice/text/section/update?userId=${userId}`,
    okMessage: 'Text section is updated',
  },
  removeTextCollection: {
    method: requestMethods.delete,
    path: (userId, id) => `/voice/text/collection?userId=${userId}&id=${id}`,
    okMessage: 'Text collection is removed',
  },
  postTextToSpeech: {
    method: requestMethods.post,
    path: (userId, id, voiceName) =>
      `/voice/tts/batch?userId=${userId}&id=${id}&voiceName=${voiceName}`,
    okMessage: 'Text-to-speech audio is generated',
  },
  pollTextToSpeech: {
    method: requestMethods.post,
    path: (sectionId) => `/voice/tts/poll?sectionId=${sectionId}`,
    okMessage: 'Text-to-speech audio is polled',
  },
  getAudioBlobUri: {
    method: requestMethods.get,
    path: (blobId) => `/voice/audio/uri?blobId=${blobId}`,
    okMessage: 'Resource uri is fetched',
  },
  uploadAudioBlobToYoutube: {
    method: requestMethods.post,
    path: (blobId) => `/voice/tts/upload/youtube?blobId=${blobId}`,
    okMessage: 'Audio is uploaded to Youtube',
  },
  getTTSVoices: {
    method: requestMethods.get,
    path: () => `/voice/tts/voices`,
    okMessage: 'TTS voices are fetched',
  },
  uploadCustomAudio: {
    method: requestMethods.post,
    path: (userId, sectionId) => `/voice/text/audio?userId=${userId}&sectionId=${sectionId}`,
    okMessage: 'Custom audio is uploaded',
  },
  getVideoBlobUri: {
    method: requestMethods.get,
    path: (blobId) => `/voice/video/uri?blobId=${blobId}`,
    okMessage: 'Resource uri is fetched',
  },
};
