import { useCallback, useState } from 'react';

const useArraySelection = (initialArray) => {
  const [array, setArray] = useState(initialArray ?? []);
  const [index, setIndex] = useState(-1);

  const onSelectElement = useCallback(
    (i, callback) => {
      if (i >= 0 && i < array.length) {
        setIndex(i);
        if (callback != null) {
          callback(array[i]);
        }
      } else {
        // console.error(`index out of bound i=${i}, length of array is ${array.length}`);
        setIndex(-1);
      }
    },
    [array]
  );

  const onSelectElementByEvent = useCallback(
    (e) => onSelectElement(e.target.value),
    [onSelectElement]
  );

  const onModifyArray = (newArray, callback) => {
    setArray(newArray ?? []);
    setIndex(newArray?.length > 0 ? 0 : -1);
    if (callback != null) {
      callback(newArray[0]);
    }
  };

  const onModifySelectElement = (key, value) => {
    const newArray = [...array];
    newArray[index][key] = value;
    setArray(newArray);
  };

  return {
    value: {
      array,
      index,
      selectedElement: array[index],
      selectedElementId: array[index]?.id ?? -1,
      selectedElementName: array[index]?.name ?? '',
    },
    onSelectElement,
    onSelectElementByEvent,
    onModifyArray,
    onModifySelectElement,
  };
};

export default useArraySelection;
