import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import BookIcon from '@mui/icons-material/Book';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DataArrayIcon from '@mui/icons-material/DataArray';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import { paths } from '../../routes/paths';

const kpicItems = [
  {
    name: 'Knowledge',
    path: paths.dashboard.logistics.kpic.root,
    icon: <DataArrayIcon />,
  },
  {
    name: 'Finance',
    path: paths.dashboard.logistics.kpic.finance,
    icon: <AttachMoneyIcon />,
  },
  {
    name: 'Capacity',
    path: paths.dashboard.logistics.kpic.capacity,
    icon: <BatteryCharging80Icon />,
  },
];

const logisticsItems = [
  {
    name: 'Calendar',
    path: paths.dashboard.logistics.calendar,
    icon: <CalendarTodayIcon />,
  },
  {
    name: 'Document',
    path: paths.dashboard.logistics.post.root,
    icon: <FolderOpenIcon />,
  },
  {
    name: 'Gallery',
    path: paths.dashboard.logistics.gallery.root,
    icon: <PhotoCameraBackIcon />,
  },
];

const analyticsItems = [
  {
    name: 'Book',
    path: paths.dashboard.analytics.book,
    icon: <BookIcon />,
  },
  {
    name: 'Text-to-Speech',
    path: paths.dashboard.analytics.tts,
    icon: <VoiceChatIcon />,
  },
  {
    name: 'Text-to-Video',
    path: paths.dashboard.analytics.ttv,
    icon: <OndemandVideoIcon />,
  },

  {
    name: 'Works',
    path: paths.dashboard.analytics.works,
    icon: <PeopleRoundedIcon />,
  },
];

const navigationGroups = [
  { label: 'Kpic', items: kpicItems },
  { label: 'Logistics', items: logisticsItems },
  { label: 'Analytics', items: analyticsItems },
];

export default navigationGroups;
