import axiosInstance from './axiosInstance';

const getRequestConfig = (request) => {
  const token = localStorage.getItem('token');

  if (token == null || token.length === 0) console.log(`invalid token: ${token}`);

  const responseType = request?.isBlob === true ? { responseType: 'blob' } : {};
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    ...responseType,
  };
};

const getResponseErrorMessage = (error) => {
  switch (error?.response?.status) {
    case 401:
      return 'Do not have user permission, please login to your account';
    case 404:
      return 'Requested resource is not found on the server';
    case 405:
      return 'Request method is not allowed on the server';
    case 500:
      return 'Internal server error, please try again later';
    default:
      console.error(error);
      return error;
  }
};

const requestInstance = async (request, args, body, maxRetries = 3) => {
  const config = getRequestConfig(request);
  const { method, path } = request;

  const completePath = path(...args);
  if (completePath.includes('undefined')) {
    alert(`path argument undefined: ${completePath}\nargs=[${args}]`);
  }

  let attempt = 0;
  while (attempt < maxRetries) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const response = await method(axiosInstance, completePath, config, body);
      // eslint-disable-next-line no-await-in-loop
      const json = await response.data;
      return { result: json, ok: true };
    } catch (error) {
      attempt += 1;
      console.error(`Attempt ${attempt} failed:`, error);
      if (attempt >= maxRetries) {
        const errorMessage = getResponseErrorMessage(error);
        return { result: null, ok: false, error, errorMessage };
      }
    }
  }
  return { result: null, ok: false, error: '' };
};

export default requestInstance;
