import { useCallback } from 'react';

const audioCtx = new AudioContext();
const analyser = audioCtx.createAnalyser();
const _frequencyData = new Uint8Array(analyser.frequencyBinCount);

const useLive2dLipSync = (model) => {
  const setMouthOpenY = useCallback(
    (v) => {
      v = Math.max(0, Math.min(1, v));

      try {
        model.internalModel.coreModel.setParameterValueById('ParamMouthOpenY', v);
      } catch (e) {
        try {
          model.internalModel.coreModel.setParamFloat('PARAM_MOUTH_OPEN_Y', v);
        } catch (e2) {
          console.error(e2);
        }
      }
    },
    [model?.internalModel?.coreModel]
  );

  const getByteFrequencyData = () => {
    analyser.getByteFrequencyData(_frequencyData);
    return _frequencyData;
  };

  const w = 20;
  const o = 80;

  const arrayAdd = (a) => a.reduce((i, t) => i + t, 0);

  const run = useCallback(() => {
    const frequencyData = getByteFrequencyData();
    const arr = [];
    for (let i = 0; i < 700; i += o) {
      arr.push(frequencyData[i]);
    }
    setMouthOpenY((arrayAdd(arr) / arr.length - 20) / 60);
    setTimeout(run, 1000 / 30);
  }, [setMouthOpenY]);

  const togglePlayback = useCallback(
    (src) => {
      audioCtx.decodeAudioData(src, (buffer) => {
        const source = audioCtx.createBufferSource();
        source.buffer = buffer;

        source.connect(audioCtx.destination);
        source.connect(analyser);

        run();
        source.start(0);
      });
    },
    [run]
  );

  const playAudio = useCallback(
    async (path) => {
      const file = await fetch(path);
      const arrayBuffer = await file.arrayBuffer();
      togglePlayback(arrayBuffer);
    },
    [togglePlayback]
  );

  return {
    playAudio,
  };
};

export default useLive2dLipSync;
