// eslint-disable-next-line import/no-extraneous-dependencies
import { Application } from '@pixi/app';
// eslint-disable-next-line import/no-extraneous-dependencies
import { InteractionManager } from '@pixi/interaction';
import { Ticker, TickerPlugin } from '@pixi/ticker';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Live2DModel } from 'pixi-live2d-display';
import * as PIXI from 'pixi.js';
import { Renderer } from 'pixi.js';
import { useCallback, useEffect, useRef } from 'react';

const useLive2dModel = (source, setCurrentModel, setting) => {
  const canvasRef = useRef(null);

  const initPixi = () => {
    Live2DModel.registerTicker(Ticker);
    Application.registerPlugin(TickerPlugin);
    Renderer.registerPlugin('interaction', InteractionManager);
    window.PIXI = PIXI;
  };

  const initLive2dModel = useCallback(async () => {
    const app = new PIXI.Application({
      view: canvasRef.current,
      autoStart: true,
      transparent: true,
      resolution: 1,
    });

    const options = {
      autoInteract: false,
      idleMotionGroup: 'sad01',
    };
    const model = await Live2DModel.from(source, options);
    app.stage.addChild(model);

    return model;
  }, [source]);

  const initLive2dGeometry = useCallback(
    (model) => {
      const parentWidth = canvasRef.current.width;
      const parentHeight = canvasRef.current.height;

      // Scale to fit the stage
      const ratio = 1.5 * Math.min(parentWidth / model.width, parentHeight / model.height);
      model.scale.set(ratio, ratio);

      // Align bottom and center horizontally
      const xOffset = setting?.xOffset ?? 0;
      const yOffset = setting?.yOffset ?? 0;
      model.x = (parentWidth - model.width) / 2 + xOffset;
      model.y = parentHeight - model.height + yOffset + 300;
    },
    [setting?.xOffset, setting?.yOffset]
  );

  const initLive2d = useCallback(async () => {
    if (!canvasRef.current) return;
    if (source == null || source.length === 0) return;

    initPixi();
    const model = await initLive2dModel();
    initLive2dGeometry(model);

    model.on('hit', (hitAreas) => {
      model.expression();
    });

    setCurrentModel(model);
    canvasRef.current.style.pointerEvents = 'none';
  }, [initLive2dModel, initLive2dGeometry, setCurrentModel, source]);

  useEffect(() => {
    initLive2d();
  }, [initLive2d]);

  return {
    canvasRef,
  };
};

export default useLive2dModel;
