const texts = {
  introZh:
    '歡迎來到MgLogi，這裡是您個人生活與工作管理的助手。我們提供了一系列實用的工具，包括月曆、文件集、相簿集，讓您輕鬆管理日常事務。此外，我們還提供了簡單易用的財政記錄、閱讀記錄、專案管理以及行動力分析功能，幫助您更好地規劃和追蹤個人目標。\n' +
    '\n' +
    '為了讓您的體驗更加豐富，我們的進階功能支援導入Reddit、Threads、E-Hentai、Arxiv等平台的最新資訊，讓您隨時掌握最新動態。同時，我們還提供文字轉語音和語音生成字幕影片的服務，讓您的內容創作更加便捷。\n' +
    '\n' +
    '最重要的是，目前我們的所有服務都是免費提供的，請盡情使用並享受我們為您帶來的便利。立即開始探索，讓我們成為您生活與工作中的得力助手！如果您有任何問題或建議，歡迎隨時聯絡網站製作人，電子郵件地址為：mglogi000@gmail.com。我們期待為您提供最優質的服務。',
  introEng:
    '\n' +
    'Welcome to MgLogi, your personal assistant for managing both your personal life and work. We offer a range of practical tools, including calendars, document collections, and photo albums, to help you easily manage your daily affairs. Additionally, we provide simple and user-friendly financial records, reading logs, project management, and productivity analysis features to help you better plan and track your personal goals.\n' +
    '\n' +
    'To enrich your experience, our advanced features support importing the latest information from platforms such as Reddit, Threads, E-Hentai, and Arxiv, allowing you to stay up-to-date with the latest trends. We also offer text-to-speech and speech-to-subtitle video services, making your content creation more convenient.\n' +
    '\n' +
    'Most importantly, all our services are currently provided free of charge. Please feel free to use them and enjoy the convenience we bring to you. Start exploring now and let us become your reliable assistant in life and work! If you have any questions or suggestions, please feel free to contact the website creator at mglogi000@gmail.com. We look forward to providing you with the best service.',
};

export default texts;
