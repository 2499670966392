import { paths } from '../../routes/paths';
import PrimaryLink from '../../standard/primary/primary-link';

const FooterSection = () => (
  <footer
    style={{
      padding: '1rem',
      textAlign: 'center',
      borderTop: '1px solid #ccc',
      marginTop: '2rem',
    }}
  >
    <p>&copy; {new Date().getFullYear()} Managerial Logistics. All rights reserved.</p>
    <PrimaryLink href={paths.termsOfService} text="Terms of Service" />
  </footer>
);

export default FooterSection;
