const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  PRODUCT: '/product',
};

export const paths = {
  termsOfService: '/terms-of-service',
  page404: '/404',
  login: '/login',
  register: '/register',
  product: {
    root: ROOTS.PRODUCT,
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
    },
    account: {
      info: `${ROOTS.DASHBOARD}/account/info`,
      live2d: `${ROOTS.DASHBOARD}/account/live2d`,
      subscription: {
        root: `${ROOTS.DASHBOARD}/account/subscription`,
      },
    },
    logistics: {
      calendar: `${ROOTS.DASHBOARD}/logistics/calendar`,
      gallery: {
        root: `${ROOTS.DASHBOARD}/logistics/gallery`,
        collection: (seriesId, collectionId) =>
          `${ROOTS.DASHBOARD}/logistics/gallery/collection/${seriesId}/${collectionId}`,
      },
      kpic: {
        root: `${ROOTS.DASHBOARD}/logistics/kpic`,
        finance: `${ROOTS.DASHBOARD}/logistics/kpic/finance`,
        capacity: `${ROOTS.DASHBOARD}/logistics/kpic/capacity`,
        info: `${ROOTS.DASHBOARD}/logistics/kpic/info`,
        log: (id) => `${ROOTS.DASHBOARD}/logistics/kpic/${id}`,
      },
      post: {
        root: `${ROOTS.DASHBOARD}/logistics/post`,
        details: (id) => `${ROOTS.DASHBOARD}/logistics/post/${id}`,
      },
    },
    analytics: {
      root: `${ROOTS.DASHBOARD}/analytics/works`,
      works: `${ROOTS.DASHBOARD}/analytics/works`,
      book: `${ROOTS.DASHBOARD}/analytics/book`,
      tts: `${ROOTS.DASHBOARD}/analytics/tts`,
      ttv: `${ROOTS.DASHBOARD}/analytics/ttv`,
    },
  },
};
