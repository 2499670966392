import { useCallback, useState } from 'react';
import requestInstance from '../../api/connection/requestInstance';
import useNotification from './useNotification';

const useRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { notifySuccess, notifyError } = useNotification();

  const onRequest = useCallback(
    async (request, args, body, isLogOk) => {
      setIsLoading(true);

      let response = await requestInstance(request, args, body);
      if (!response.ok && response?.error?.response?.status === 401) {
        try {
          response = requestInstance(request, args, body);
        } catch (error) {
          notifyError(error);
          return { result: null, ok: false };
        }
      }

      if (response.ok) {
        if (isLogOk) {
          notifySuccess(request.okMessage);
        }
      } else {
        notifyError(response.errorMessage);
      }

      setIsLoading(false);
      return response;
    },
    [notifyError, notifySuccess]
  );

  return { onRequest, isLoading };
};

export default useRequest;
