import { useCallback, useContext, useRef } from 'react';
import { voiceApi } from '../../../../../api/speech/voice';
import { UserContext } from '../../../../../auth/context/user';
import { useBoolean } from '../../../../../hooks/use-boolean';
import useString from '../../../../../hooks/use-string';
import useRequest from '../../../../../standard/hooks/useRequest';
import useVoiceList from '../../../../../standard/hooks/useVoiceList';
import useLive2dLipSync from './use-live2d-lip-sync';

const useLive2dAudio = (model) => {
  const { userId } = useContext(UserContext);
  const { onRequest, isLoading } = useRequest();
  const voiceList = useVoiceList();

  const audioRef = useRef(null);
  const isPlaying = useBoolean(false);
  const inputText = useString('');

  const { playAudio } = useLive2dLipSync(model);

  const onTextToSpeech = useCallback(
    async (text) => {
      const body = {
        text: text ?? inputText.value,
        voiceName: voiceList.value.selectedElement?.name,
      };
      const response = await onRequest(voiceApi.getTextToSpeechWav, [], body, true);
      await playAudio(response?.result);
    },
    [inputText.value, onRequest, playAudio, voiceList.value.selectedElement?.name]
  );

  const onSloganToSpeech = useCallback(
    async (i) => {
      const voiceName = voiceList.value.selectedElement?.name;
      const response = await onRequest(
        voiceApi.getSloganToSpeechWav,
        [userId, i, voiceName],
        null,
        true
      );
      await playAudio(response?.result);
    },
    [onRequest, playAudio, userId, voiceList.value.selectedElement?.name]
  );

  return {
    audioRef,
    isPlaying,
    isLoading,
    inputText,
    voiceList,
    playAudio,
    onTextToSpeech,
    onSloganToSpeech,
  };
};

export default useLive2dAudio;
