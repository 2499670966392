import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Dropdown from '@mui/joy/Dropdown';
import ListDivider from '@mui/joy/ListDivider';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../../auth/context/user';
import useAuth from '../../pages/auth/hooks/use-auth';
import { useRouter } from '../../routes/hook';
import { paths } from '../../routes/paths';
import PrimaryButton from '../../standard/primary/primary-button';
import ChiIcon from './img.png';

const HeaderDropdown = () => {
  const router = useRouter();
  const { username, userRole } = useContext(UserContext);
  const { isAccountExist, isAccessTokenExist, onSignOut } = useAuth();

  return (
    <Dropdown>
      {!isAccountExist && !isAccessTokenExist ? (
        <PrimaryButton onClick={() => router.push(paths.login)}>Sign in</PrimaryButton>
      ) : (
        <MenuButton
          variant="plain"
          size="sm"
          sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
        >
          <Avatar src={ChiIcon} sx={{ maxWidth: '32px', maxHeight: '32px' }} />
        </MenuButton>
      )}

      <Menu
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: '99999',
          gap: 1,
          '--ListItem-radius': 'var(--joy-radius-sm)',
        }}
      >
        <MenuItem onClick={() => router.push(paths.dashboard.account.info)}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar src={ChiIcon} sx={{ borderRadius: '50%' }} />
            <Box sx={{ ml: 1.5 }}>
              <Typography level="title-sm" textColor="text.primary">
                {username}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        <ListDivider />
        {(userRole === 'Owner' || userRole === 'Admin' || userRole === 'Tester') && (
          <>
            <MenuItem onClick={() => router.push(paths.dashboard.account.subscription.root)}>
              <ShoppingCartIcon />
              Subscription
            </MenuItem>
            <MenuItem onClick={() => router.push(paths.dashboard.account.live2d)}>
              <MusicNoteIcon />
              Live 2d
            </MenuItem>
          </>
        )}
        <MenuItem onClick={onSignOut}>
          <LogoutRoundedIcon />
          Sign out
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default HeaderDropdown;
