import PropTypes from 'prop-types';
import useLive2dModel from '../hooks/use-live2d-model';

const Live2dCanvas = ({ source, setCurrentModel, setting }) => {
  const { canvasRef } = useLive2dModel(source, setCurrentModel, setting);
  return <canvas ref={canvasRef} width="400" height="900" />;
};

export default Live2dCanvas;

Live2dCanvas.propTypes = {
  source: PropTypes.string,
  setCurrentModel: PropTypes.func,
  setting: PropTypes.object,
};
