import Link from "@mui/joy/Link";
// @mui
import PropTypes from "prop-types";
import { forwardRef } from "react";
// routes
import { RouterLink } from "src/routes/components";
import Icon from "./phi.png";
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => (
  <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
    <img src={Icon} alt="icon" style={{ width: '24px', height: '24px' }} />
  </Link>
));

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
