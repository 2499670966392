import Box from '@mui/joy/Box';
import { useRouter } from '../../routes/hook';
import { paths } from '../../routes/paths';
import ColumnStack from '../../standard/primary/column-stack';
import PrimaryButton from '../../standard/primary/primary-button';
import FooterSection from '../others/footer-section';
import WelcomeHeader from './components/welcome-header';

const ProductPage = () => {
  const router = useRouter();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 4,
        textAlign: 'center',
      }}
    >
      <ColumnStack>
        <WelcomeHeader />
        <Box>
          <PrimaryButton onClick={() => router.push(paths.register)}>Sign Up Now</PrimaryButton>
        </Box>
        <FooterSection />
      </ColumnStack>
    </Box>
  );
};

export default ProductPage;
